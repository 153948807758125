import React from 'react';
import { graphql } from 'gatsby';
import { navigate } from '@reach/router';
import { Detail, Seo } from 'components';

export default ({
  data: {
    page: {
      frontmatter: { seo_title, title, redirect, og, description },
      body
    }
  }
}) => {
  if (redirect) {
    navigate(redirect);
  }

  return (
    <>
      <Seo title={seo_title || title + ' | статья Nimax'} og={og} description={description} />
      <Detail title={title}>{body}</Detail>
    </>
  );
};

export const pageQuery = graphql`
  query($id: String!) {
    page: mdx(id: {eq: $id}) {
      frontmatter {
        seo_title
        title
        redirect
        og
        description
      }
      body
    }
  }
`;
